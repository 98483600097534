.table_search {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #368fcd;
  width: max-content;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.table_search input {
  border: none;
  font-family: inherit;
  font-size: 1rem;
  outline: none;
  width: 100%;
  background-color: transparent;
}
