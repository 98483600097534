.modal_action_btn {
  all: unset;
  color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 0.625rem 0;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
}

.modal_action_btn:hover,
.modal_action_btn:focus {
  opacity: 0.9;
}

.modal_action_btn:focus {
  outline: none;
}
