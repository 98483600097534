@import url('bootstrap/dist/css/bootstrap.min.css');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/************************************/

body {
  margin: 0 !important;
  background-color: #f4f6fa;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

p {
  margin: 0;
}

input,
select {
  background-color: #fff;
}

.bg-light {
  background-color: #f4f6fa !important;
}

.edit-img,
.delete-img {
  width: 30px;
  cursor: pointer;
  padding: 0px 5px;
}

#formTitle {
  font-weight: bold;
}

/***************************************/

.btn {
  display: inline-block;
  background: linear-gradient(13deg, #2e6092 0%, #2d62de 100%) 0% 0%;
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: opacity 200ms ease-out;
  padding: 10px 20px;
}

.btn:hover,
.btn:focus {
  opacity: 0.9;
  color: #fff;
}

#root {
  height: 100vh;
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textCapitalize {
  text-transform: capitalize;
}

.centerSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
