.btn_sm {
  height: fit-content;
  font-size: 1rem;
}
.th_edit {
  width: 15%;
}

.user_form {
  border-radius: 5px;
  padding: 30px 0px;
  flex-direction: row;
  justify-content: space-between;
}

.user_form_card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 15px;
}

.user_form_buttons {
  text-align: right;
}

.form_btn {
  height: fit-content;
  font-size: 1rem;
}

.form_btn:hover {
  opacity: 0.9;
  color: #fff;
}

.hr {
  margin: 1.6rem 0;
  color: inherit;
  background-color: #2e61a6;
  border: 0;
  opacity: 0.25;
}

.user_list {
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user_list > h6 {
  width: 100%;
  margin-top: 3px;
}

.user_form_row {
  display: flex;
}

.user_form_input {
  width: stretch;
}

.user_form_input > input {
  background: #f4f6fa;
  font-size: 1rem;
  font-weight: 500;
}

.user_form_select {
  width: stretch;
}

.user_container {
  max-width: 1300px;
  margin: auto;
  padding: 0 20px 20px 20px;
}

.user_form_select > select {
  background: #f4f6fa;
  font-size: 1rem;
  font-weight: 500;
}

.search_form {
  width: 100%;
  max-width: 285px;
  text-align: -webkit-right;
}

.search_input {
  background-color: #fff;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  width: 120px;
  display: block;
  padding: 0.3rem 0.6rem;
  margin-bottom: 0.9rem;
  border: 1px solid transparent;
  outline: none;
  border-radius: 5px;
  transition: all 200ms ease-out;
}

.search_input:hover,
.search_input:focus {
  border: 1px solid #c5c5c5;
}

.error {
  border: 1px solid red;
}

.valid {
  border: 1px solid transparent;
}

@media only screen and (max-width: 1024px) {
  .user_form,
  .user_list {
    margin: 0%;
  }
}
