.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1.25rem;
}

.form_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  max-width: 514px;
  height: 562px;
  padding: 3.75rem 3.75rem 4.375rem 3.75rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.welcome_text {
  color: #1a3754;
  font-size: 1.875rem;
  font-weight: 600;
  margin: 2.625rem 0;
}

.login_form {
  width: 100%;
  max-width: 285px;
}

.user_input {
  background-color: #f4f6fa;
  font-weight: 500;
  width: 100%;
  display: block;
  padding: 0.8125rem;
  margin-bottom: 0.9375rem;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 200ms ease-out;
}

.user_input:hover,
.user_input:focus {
  border: 1px solid #2e6092;
}

.user_input::placeholder {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  color: #8b8181;
}

.login_button {
  padding: 10px 0;
  width: 100%;
  margin-top: 30px;
}
