.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.modal {
  position: fixed;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 200;
}

.modal_content_wrapper {
  margin: 0.9375rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.9375rem;
}

.modal_text {
  font-size: 1.1875rem;
  text-align: center;
}

.modal_buttons_wrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
}
