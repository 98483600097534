.task_container {
  max-width: 1300px;
  margin: auto;
  padding: 0 20px 20px 20px;
}

.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task_form_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.task_form {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr;
}

@media (min-width: 750px) {
  .task_form {
    grid-template-columns: 1fr 1fr;
  }
}
