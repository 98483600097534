.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0px;
  border-width: 1px;
  border-radius: 2px;
  border-color: #acacac;
  border-style: dashed;
  background-color: #ffffff;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin: 0px 20px;
  text-align: center;
  margin-bottom: 1.5rem !important;
}

section > aside {
  margin: 0px 20px;
}

section > aside > h4 {
  font-size: 1rem;
}

.dropzone > h6 {
  font-size: 1.1rem;
  font-weight: 600;
}

.dropzone > p {
  font-size: 0.8rem;
  color: #949494;
  font-weight: 500;
}

.dropzone > .upload_btn {
  font-size: 0.6rem;
  width: 120px;
  height: 30px;
  border-radius: 5px;
}

.upload_card {
  margin: 0 22vh;
  border: 1.5px solid #e9ecef;
}

@media only screen and (max-width: 1024px) {
  .upload_card {
    margin: 0%;
  }
}
