.form_wrapper {
  display: flex;
  gap: 3px;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.form_wrapper input {
  outline: none;
  padding: 8px 10px;
  font-size: 1rem;
  border-radius: 5px;
  width: 100%;
}

.form_wrapper select {
  outline: none;
  padding: 11px 10px;
  font-size: 1rem;
  border-radius: 5px;
  width: 100%;
}

.error {
  border: 1px solid red;
}

.valid {
  border: 1px solid #000;
}

.textError {
  color: red;
  font-size: 0.8rem;
  font-weight: 500;
  position: absolute;
  bottom: -22px;
  left: 0;
  white-space: nowrap;
}
