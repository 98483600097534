.table_container {
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  min-width: 1100px;
  width: 100%;
}

.table th {
  font-size: 0.9375rem;
  font-weight: 400;
  color: #adb5bd;
  padding: 0 20px 20px 20px;
  white-space: nowrap;
}

.table th:nth-last-child(1) {
  text-align: right;
}

.table td {
  font-size: 0.9375rem;
  border-bottom: 10px solid #f4f6fa;
  white-space: nowrap;
  background-color: #fff;
  padding: 20px;
}
